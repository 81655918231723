import React, { useRef, useLayoutEffect } from 'react';
import uberspaceBadge from '/static/uberspace-badge.png';
import {
  H2,
  Heading,
  Main,
  Paragraph,
  Section,
  Small,
  UberspaceSection,
} from './elements';

const i18n = {
  header: <>Imprint</>,
  sections: [
    <Section key="address">
      <Paragraph>
        Andreas Mischke<br />
        Kunigundendamm 25<br />
        96050 Bamberg<br />
        <a href="tel:+4995125295472">+49 951 25 29 54 72</a><br />
        <a href="mailto:dada3359@mischke.me">dada3359@mischke.me</a><br />
        <Small>
          * Please do not store this email address to your address book.
          It&nbsp;might change any time.
        </Small>
      </Paragraph>
    </Section>,
    <Section key="tracking">
      <H2>Data Protection</H2>
      <Paragraph>
        This website does not use cookies or any other form of tracking.
        Server requests are logged and contain date and time, the retrieved URL
        path, the referrer, the browser used and the first 16 bits of your IP
        address.
      </Paragraph>
      <Paragraph>
        The telephone number above is hosted by sipgate GmbH, Gladbacher
        Straße&nbsp;74, 40219&nbsp;Düsseldorf. If you call the number and leave
        a message, sipgate GmbH will automatically transcribe it and send the
        transcript as well as the voice recording to me by e-mail.
      </Paragraph>
    </Section>,
    <UberspaceSection key="uberspace">
      <a href="https://uberspace.de/">
        <img src={uberspaceBadge} />
      </a>
    </UberspaceSection>
  ],
};

export default function Imprint() {
  const ref = useRef();

  useLayoutEffect(() => {
    ref.current.parentElement.scrollTo(0, 0);
  }, [ref]);

  return (
    <Main ref={ref}>
      <Heading>{i18n.header}</Heading>
      {i18n.sections}
    </Main>
  );
}
