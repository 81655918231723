import React from 'react';
import logoGithub from '/static/logo-github.svg';
import logoTwitter from '/static/logo-twitter.svg';

import {
  Footer,
  Heading,
  Main,
  Paragraph,
  SocialLink,
  Tagline,
} from './elements';
import { Link } from 'react-router-dom';

const i18n = {
  greeting: "Hi, I'm Andreas!",
  tagline: 'I build web applications for desktop and mobile.',
  introduction: (
    <>
      I&nbsp;am a fullstack web developer with focus on user experience and accessibility.
      My&nbsp;favorite technologies are Node.js and React but I&nbsp;am experienced in using other
      programming languages and frameworks as well. 
    </>
  ),
  socialLinks: [
    {
      icon: logoGithub,
      url: "https://github.com/andreasmischke",
      text: 'github.com/​andreasmischke',
    },
    {
      icon: logoTwitter,
      url: "https://twitter.com/andreasmischke",
      text: 'twitter.com/​andreasmischke',
    },
  ],
  imprint: 'Imprint',
};

export default function FrontPage() {
  return (
    <>
      <Main>
        <Heading>{i18n.greeting}</Heading>
        <Tagline>{i18n.tagline}</Tagline>
        <Paragraph>{i18n.introduction}</Paragraph>
        <Paragraph>
          {i18n.socialLinks.map(({ icon, url, text }) => (
            <SocialLink
              key={url}
              icon={icon}
              href={url}
            >{text}</SocialLink>
          ))}
        </Paragraph>
      </Main>
      <Footer>
        <Link to="/imprint">{i18n.imprint}</Link>
      </Footer>
    </>
  );
}
