import styled from 'styled-components';

export { default as Picture } from './Picture';

export const Wrapper = styled.div`
  display: flex;
  background: ${props => props.theme.backgroundColor};

  @media (orientation: landscape) {
    flex-direction: row;
  }
  @media (orientation: portrait) {
    flex-direction: column;
    min-height: 100vh;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0 2rem;

  @media(orientation: portrait) {
    margin-top: 5vh;
    flex-direction: column;
  }

  @media(orientation: landscape) {
    margin-left: min(50vw, ${props => props.wide ? '20vh' : '100vh'});
    min-height: 100vh;
    flex-direction: column-reverse;
  }
`;
