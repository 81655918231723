import React from 'react';
import { createGlobalStyle } from "styled-components";
import FrontPage from '../FrontPage';
import ImprintPage from '../ImprintPage';
import {
  Content,
  Picture,
  Wrapper,
} from './elements';
import ThemeContext from './contexts/ThemeContext';
import { BrowserRouter, Route } from 'react-router-dom';
import BackButton from './BackButton';

const GlobalStyles = createGlobalStyle`
html {
  margin: 0;
  font-size: 8px;

  @media (orientation: portrait) {
    @media only screen and (max-width: 200px) {
      font-size: 6px;
    }
  }

  &::after {
    display: none;
    content: "";
    position: absolute;
    top: 4px;
    left: 0px;
    right: 0px;
    height: calc(100vh - 4px);
    z-index: 5000;
    pointer-events: none;
    background: repeating-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 8px, rgba(0, 0, 0, 0.15) 8px, rgba(0, 0, 0, 0.15) 16px);
  }
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;

export default function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeContext>
        <Wrapper>
          <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
            <Picture>
              <BackButton />
            </Picture>
            <Content>
              <Route exact path="/imprint">
                <ImprintPage />
              </Route>
              <Route exact path="/">
                <FrontPage />
              </Route>
            </Content>
          </BrowserRouter>
        </Wrapper>
      </ThemeContext>
    </>
  );
}
