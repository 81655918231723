import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (orientation: portrait) {
    justify-content: flex-start;
  }
  @media (orientation: landscape) {
    justify-content: center;
  }
`;

export const Heading = styled.h1`
  font-size: 4rem;
  font-weight: 400;
  line-height: 4rem;
  margin: 1rem 0;

  @media(orientation: portrait) {
    text-align: center;
  }
`;

export const Tagline = styled.h2`
  font-size: 4rem;
  font-weight: 400;
  line-height: 4rem;
  margin: 1rem 0;

  @media(orientation: portrait) {
    text-align: center;
  }
`;

export const Paragraph = styled.p`
  font-size: 2rem;
  line-height: 3rem;
  margin: 2rem 0;
  padding: 0;
  transform: translateY(.25rem);
  max-width: 80rem;
`;

export const SocialLink = styled.a`
  display: block;
  font-size: 2rem;
  line-height: 3rem;
  margin: 1rem 0;
  padding: 0 0 0 4rem;
  background-image: url(${props => props.icon});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Footer = styled.footer`
  line-height: 4rem;
  height: 4rem;
  font-size: 1.5rem;
`;
