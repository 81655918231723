import React from 'react';
import { ThemeProvider } from 'styled-components';
import themes from '/config/themes';

export default function ThemeContext({ children }) {
  return (
    <ThemeProvider theme={themes.light}>
      {children}
    </ThemeProvider>
  )
}
