import styled from 'styled-components';

export const Heading = styled.h1`
  font-size: 4rem;
  font-weight: 400;
  line-height: 4rem;
  margin: 1rem 0;

  @media(orientation: portrait) {
    text-align: right;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: start;
  font-size: 2rem;

  @media(orientation: landscape) {
    padding: 4rem 0 10rem;
    max-width: 80rem;
  }
`;

export const Section = styled.section`
  line-height: 3rem;
`;

export const H2 = styled.h2`
  font-size: 3rem;
  font-weight: 400;
  margin: 3.5rem 0 2rem;
`;

export const Small = styled.small`
  display: block;
  font-size: 1.5rem;
  padding-top: 0.2rem;
`;

export const Paragraph = styled.p`
  margin: 1rem 0;
  padding-top: .25rem;
`;

export const UberspaceSection = styled.section`
  align-self: center;
  margin: 4rem 0 2rem;
`;
