import React from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const arrowStroke = css`
  content: "";
  background: ${props => props.theme.shadedText};
  width: .6em;
  height: .15em;
  border-radius: .05em;
  margin: .08em;
`;

const LinkBack = styled(Link)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  font-size: 4rem;
  width: 1.5em;
  line-height: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  text-align: center;
  top: 0.5em;
  left: 0.5em;
  box-shadow: 0 0.1em 0.5rem rgba(0, 0, 0, .25);

  &:before {
    ${arrowStroke}
    transform: rotate(-45deg);
  }
  &:after {
    ${arrowStroke}
    transform: rotate(45deg);
  }
`;

export default function BackButton() {
  const { pathname } = useLocation();

  if (pathname === '/') {
    return null;
  }

  return (
    <LinkBack to="/" aria-label="Back to Start Page" />
  );
}
