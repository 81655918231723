import styled from 'styled-components';
import thumbnail from '/static/andreas@120x160.jpg';
import background360 from '/static/andreas@360x480.jpg';
import background720 from '/static/andreas@720x960.jpg';
import background1080 from '/static/andreas@1080x1440.jpg';
import background1440 from '/static/andreas@1440x1920.jpg';
import background2160 from '/static/andreas@2160x2880.jpg';

export default styled.div`
  background-size: cover;
  background-position: ${props => props.small ? '0 0' : '50% 25%'};
  display: flex;
  flex-shrink: 0;
  justify-content: ${props => props.small ? 'space-between' : 'flex-end'};

  background-image: url(${background2160});

  @media (orientation: landscape) {
    position: fixed;
    height: 100vh;
    width: ${props => props.small ? '20vh' : '100vh'};
    max-width: 50vw;
    border-right: 1px solid ${props => props.theme.backgroundColor}; // Fix mis-calculations in pixel rounding

    @media only screen and (max-height: 1920px),
      only screen and (max-height: 960x) and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(${background1440}), url(${thumbnail});
    }
    @media only screen and (max-height: 1440px),
      only screen and (max-height: 720px) and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(${background1080}), url(${thumbnail});
    }
    @media only screen and (max-height: 960px),
      only screen and (max-height: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(${background720}), url(${thumbnail});
    }
    @media only screen and (max-height: 480px) {
        background-image: url(${background360}), url(${thumbnail});
    }

    &:after {
      content: '';
      border-top: 100vh solid transparent;
      border-right: 20vh solid ${props => props.theme.backgroundColor};
    }
  }
  @media (orientation: portrait) {
    height: ${props => props.small ? '20vw' : '100vw'};
    max-height: 50vh;
    border-bottom: 1px solid ${props => props.theme.backgroundColor}; // Fix mis-calculations in pixel rounding

    @media only screen and (max-width: 1440px),
      only screen and (max-width: 720px) and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(${background1440}), url(${thumbnail});
    }
    @media only screen and (max-width: 1080px),
      only screen and (max-width: 540px) and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(${background1080}), url(${thumbnail});
    }
    @media only screen and (max-width: 720px),
      only screen and (max-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(${background720}), url(${thumbnail});
    }
    @media only screen and (max-width: 360px) {
        background-image: url(${background360}), url(${thumbnail});
    }

    &:after {
      content: '';
      border-left: 100vw solid transparent;
      border-bottom: 20vw solid ${props => props.theme.backgroundColor};
    }
  }
`;
